import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`“True Deload Week”`}</em></p>
    <p>{`30:00 AMRAP of:`}</p>
    <p>{`500M Row`}</p>
    <p>{`20 Stationary Alternating Lunges (10/Leg)`}</p>
    <p>{`15 Pushups`}</p>
    <p>{`10 Ring Rows`}</p>
    <p>{`5 Inch Worms`}</p>
    <p><em parentName="p">{`*`}{`work at about 70% intensity, just stay moving.`}</em></p>
    <p>{`then,`}</p>
    <p>{`Shoulder, Wrist, Forearm, Lat & Core Mobility & Stretching.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      